.button {
  background-color: var(--c_main_dark);
  color: white;
  padding: 13px 20px;
  font-size: 17px;
  font-family: HIMALAYA, Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.button:hover {
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.7));
}
