.header {
  z-index: 1000;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 15px;
  font-family: Vogue;
  letter-spacing: 2px;
}
.menuIcon {
  display: none;
  color: white;
  font-size: 26px;
  cursor: pointer;
}
.logo {
  width: 230px;
  padding: 2px;
  cursor: pointer;
}
.header ul {
  list-style-type: none;
  padding: 0;
}
.li {
  color: white;
  display: inline;
  margin: 0px 5px;
  padding: 3px 5px;
  font-size: 19px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
.li:after {
  content: "";
  height: 1px;
  width: 0px;
  background-color: white;
  position: absolute;
  bottom: -1px;
  right: 0px;
  transition: 0.3s;
}
.li:hover::after {
  width: 100%;
  left: 0%;
}
.active {
  border-bottom: 1px solid white;
}
@media (max-width: 786px) {
  .header {
    justify-content: space-between;
  }
  .header nav {
    display: none;
  }
  .menuIcon {
    display: block;
  }
}
/* ---------- NAV ------------- */
.nav {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--c_main);
  background-color: rgba(var(--c_main_rgb), 0.85);
  backdrop-filter: blur(6px);
  color: var(--c_main_dark);
  width: 100%;
  height: 100vh;
  box-shadow: 1px 2px 6px 2px rgb(0 0 0 / 40%);
  padding: 20px 10px;
  animation: leftToRight 1s;
  transition: all 1s;
  overflow-x: hidden;
}
.closing {
  animation: rightToLeft 1s forwards;
}
@keyframes leftToRight {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@keyframes rightToLeft {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
.title {
  text-align: center;
  margin-top: 10px;
  font-size: 1.45rem;
  line-height: 1.33em;
  letter-spacing: 0.2em;
  font-family: TheanoDidot-Regular;
  font-weight: bold;
  text-transform: uppercase;
}
.nav ul {
  list-style-type: none;
  padding: 10px;
  font-size: 18px;
}
.navLi {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none !important;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  width: 60%;
  margin: 5px auto;
  color: inherit !important;
  font-size: 0.75rem;
  line-height: 1.33em;
  letter-spacing: 0.2em;
  font-family: TheanoDidot-Regular;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid black;
}
.navActive {
}
.closeIcon {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 24px;
  cursor: pointer;
}
@media (max-width: 500px) {
  .navLi {
    width: 85%;
  }
}
