.footer {
  z-index: 999;
  background: var(--c_main_dark);
  color: white;
  margin-top: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  padding: 5px 2px;
}
.footer p {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  margin: 0px;
  padding: 5px;
  width: fit-content;
  margin: 0 auto;
}
.website {
  color: white;
  text-decoration: none;
}
.socialIcons {
  margin: 0px;
  padding: 5px;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 27px;
  cursor: pointer;
}
.fb,
.ig,
.mail {
  color: white;
  text-decoration: none;
  transition: 0.2s;
}
.fb:hover,
.ig:hover,
.mail:hover {
  padding: 2px;
  background-color: white;
  border-radius: 50%;
}
.fb:hover {
  color: var(--c_fb);
}
.ig:hover {
  color: var(--c_ig);
}
.mail:hover {
  color: var(--c_mail);
}
