.logo {
  height: 95px;
  width: 95px;
  cursor: pointer;
  opacity: 0;
  filter: blur(4px);
  transition: opacity 1s ease, filter 1s ease;
}
.logo.loaded {
  opacity: 1;
  filter: blur(0);
}
@media (max-width: 1000px) {
  .logo {
    height: 75px;
    width: 75px;
  }
}
