@import url("./styles/colors.css");

body {
  color: black;
  background-color: rgba(75, 75, 75, 0.6);
  background-color: white;
  margin: 0;
  font-family: Questrial-Regular, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "HIMALAYA";
  src: local("HIMALAYA"), url(./fonts/Himalaya-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "VOGUE";
  src: local("VOGUE"), url(./fonts/VOGUE.TTF) format("truetype");
}

@font-face {
  font-family: "Questrial-Regular";
  src: local("Questrial-Regular"),
    url(./fonts/Questrial-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SandeMoreDemo-SemiBold";
  src: local("SandeMoreDemo-SemiBold"),
    url(./fonts/SandeMoreDemo-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "CormorantGaramond";
  src: local("CormorantGaramond"),
    url(./fonts/CormorantGaramond-Regular.ttf) format("truetype");
}
