.aboutmeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 60px 20px;
}
.profile {
  width: 400px !important;
  height: auto !important;
}
.aboutmeContainer p {
  text-align: center;
  font-size: 24px;
  overflow-y: auto;
  max-height: 100%;
}
.imagesContainer {
  line-height: 0;
  column-count: 3;
  column-gap: 0px;
  padding: 3.125rem 5.625rem;
}
.imagesContainer img {
  width: 100% !important;
  height: auto !important;
  padding: 20px;
  cursor: pointer;
}
@media (max-width: 1300px) {
  .aboutmeContainer {
    display: block;
    height: auto;
  }
  .profileContainer {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 500px) {
  .profile {
    display: flex;
    max-width: 85%;
    margin: 0 auto;
  }
}
/* PORTOFOLIO */
@media (max-width: 1000px) {
  .imagesContainer {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 800px) {
  .imagesContainer {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 500px) {
  .imagesContainer {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    padding: 0.5rem 1rem;
  }
}
