.typingText {
  position: absolute;
  z-index: 50;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: SandeMoreDemo-SemiBold, Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: normal !important;
  text-shadow: 0px 0px 20px black !important;
  text-align: center;
}

@media (max-width: 570px) {
  .typingText {
    font-size: 25px;
    margin-bottom: 200px;
  }
}
