.photoSection {
  background-color: var(--c_main);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 5px;
}
.textContainer {
  padding: 5px;
}
.textContainer p {
  width: 310px;
  text-align: center;
  font-size: 26px;
  opacity: 0.5;
}
.text p:last-child {
  opacity: 1;
}

/*.videoSection {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 65vh;
  overflow: hidden;
  width: 100%;
}
iframe {
  width: 300%;
  height: 100%;
  margin-left: -100%;
  pointer-events: none;
}*/
.test54 {
  position: relative;
  background: var(--c_main_dark);
  width: auto;
  height: 85vh;
  overflow: hidden;
}
.videoSection {
  width: 100%;
  height: 100%;
}
iframe {
  width: 300%;
  height: 100%;
  margin-left: -100%;
  pointer-events: none;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
.buttonFilms {
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.postersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  padding: 0px;
  padding: 30px 0px;
  gap: 35px;
}
.postersContainer img:first-child {
  width: 400px !important; /*250*/
  height: auto !important;
}
.middle {
  padding-top: 50px !important;
  width: 400px !important; /*250*/
  height: auto;
}
.postersContainer img:last-child {
  width: 450px; /*300*/
  height: auto;
}
@media (max-width: 1607px) {
  .photoSection {
    display: block;
  }
  .textContainer p {
    max-width: 100%;
    margin: 15px auto;
  }
}
@media (max-width: 1342px) {
  .postersContainer {
    display: block;
    max-width: 50%;
    margin: 0 auto;
  }
  .postersContainer img {
    padding: 0px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 10px !important;
  }
}
@media (max-width: 900px) {
  .postersContainer {
    max-width: 80%;
  }
}
