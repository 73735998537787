.container {
  line-height: 0;
  column-count: 3;
  column-gap: 0px;
  padding: 3.125rem 5.625rem;
}
.container img {
  width: 100% !important;
  height: auto !important;
  padding: 20px;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .container {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 800px) {
  .container {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 500px) {
  .container {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    padding: 0.5rem 1rem;
  }
}
