.container {
  color: var(--c_active);
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 200px;
  font-family: TYPO_GROTESK_DEMO, Arial, Helvetica, sans-serif;
}
.container h1 {
  margin: 0;
  font-size: 100px;
}
.container h2 {
  margin: 0;
  font-size: 35px;
}
.button {
  margin: 10px auto;
}
