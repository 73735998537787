.form {
  width: 40%;
  margin: 0 auto;
  padding: 30px 0;
  font-family: CormorantGaramond, sans-serif;
}
.form input,
select,
textarea {
  background-color: var(--c_main);
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 18px;
  /*font-family: Questrial-Regular, Arial, Helvetica, sans-serif;*/
  font-family: CormorantGaramond, sans-serif;
  border-radius: 0px;
  margin-bottom: 15px;
  margin-top: 5px;
}
.form input:focus,
.form select:focus,
.form textarea:focus {
  outline: 2px solid var(--c_main_dark);
}
.form input[type="radio"] {
  outline: none !important;
}
.label {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.25em;
  min-width: 100%;
}
.servicesContainer {
  background-color: var(--c_main);
  margin-bottom: 15px;
}
.servicesContainer input {
  width: auto !important;
  margin: 0px;
  margin: 5px;
}
.buttonFlex {
  display: flex;
  justify-content: center;
}
.form textarea {
  resize: vertical;
  min-height: 120px;
}
.inputError {
  background-color: rgba(var(--c_error_rgb), 0.35) !important;
  outline: 2px solid var(--c_error) !important;
}
@media (max-width: 1000px) {
  .form {
    width: 70%;
  }
}
@media (max-width: 600px) {
  .form {
    width: 95%;
  }
}
