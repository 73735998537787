.container {
  background-color: var(--c_active);
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  color: transparent;
}
.container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  filter: brightness(0.93);
}
.container img.loaded {
  opacity: 1;
}
.container::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.9)
  );
  z-index: 1;
}
.container::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 30%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  z-index: 1;
}
.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: SandeMoreDemo-SemiBold, Arial, Helvetica, sans-serif;
  font-size: 55px;
  font-weight: normal !important;
  z-index: 50;
  text-align: center;
  max-width: 50%;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.7);
  cursor: pointer;
  text-decoration: none;
}
