:root {
  --c_main: rgb(235, 235, 235);
  --c_main_rgb: 235, 235, 235;
  --c_main_dark: rgb(75, 75, 75);
  --c_main_dark_rgb: 75, 75, 75;
  --c_active: rgb(32, 32, 32);
  --c_fb: rgb(66 103 178);
  --c_ig: rgb(193 53 132);
  --c_mail: rgb(199, 68, 16);
  --c_error: rgb(219, 83, 83);
  --c_error_rgb: 219, 83, 83;
  --c_success: rgba(77, 207, 84, 0.9);
}
