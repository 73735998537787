.container {
  position: fixed;
  z-index: 9999999999;
  height: 100vh;
  width: 100%;
  animation: popup 0.7s;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(3px);
}
.box {
  background-color: var(--c_success);
  color: white;
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 20px;
  padding: 10px 25px;
  font-size: 24px;
  font-family: Questrial-Regular, Arial, Helvetica, sans-serif;
}
@keyframes popup {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.error {
  background-color: rgba(var(--c_error_rgb), 0.8);
}
.loading {
  background-color: rgba(33, 125, 245, 0.8);
}
