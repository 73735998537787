.container {
  background-color: var(--c_main);
  margin: 50px;
  padding: 100px 10px;
  font-family: Questrial-Regular, Arial, Helvetica, sans-serif;
  font-size: 22px;
  text-align: center;
}
@media (max-width: 650px) {
  .container {
    margin: 20px;
    padding: 80px 10px;
  }
}
